import React from 'react';
import DashboardWrapper from '../../containers/Dashboard/DashboardWrapper';
import MentorcampsWrapper from '../../containers/Dashboard/MentorcampsWrapper/MentorcampsWrapper';

const DashboardHome = ({ location, pageContext }) => {
  return (
    <DashboardWrapper pageContext={pageContext} location={location}>
      <MentorcampsWrapper />
    </DashboardWrapper>
  );
};

export default DashboardHome;
