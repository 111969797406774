import React, { useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import useSetState from '../../../utils/useSetState';
import fetch_data from './utils/fetch_data';
import styles from './courses.module.css';
import { LangContext } from '../../context/LangContext';

const MentorcampsWrapper = () => {
  const { translations } = useContext(LangContext);

  const [state, setState] = useSetState({
    courses: []
  });

  useEffect(() => {
    fetch_data({ setState });
  }, []);

  return (
    <div>
      <div className={styles.introBox}>
        <h2 className="mainTitle">
          Available Tracks{' '}
          <img className="imgIcon mediumIcon" src="/images/icons/book.png" />
        </h2>
        <p className={styles.introBoxParagraph + ' mainSubtitle'}>
          Explore all our e-learning tracks with projects, challenges and videos
        </p>
      </div>

      {state.courses.map((course) => {
        return <Card course={course} translations={translations} />;
      })}
    </div>
  );
};

export default MentorcampsWrapper;

const Card = ({ course, translations }) => {
  return (
    <div className={styles.card}>
      <Link to={`/dashboard/course?_id=${course._id}`}>
        <div
          style={{ backgroundImage: `url("${course.img_url}")` }}
          className={styles.card_img}
        />
        <div className={styles.info_section}>
          <p className={styles.card__title}>{course.name}</p>
          <div className={styles.bottom_section}>
            <p>{translations.cardEnterText}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
