import { api_base_url } from '../../../../data/client';
import axios from 'axios';

const fetch_data = async ({
  userGlobalState,
  setState,
  setUserGlobalState
}) => {
  // const { _id, family } = userGlobalState.user;

  const courses_res = await axios({
    url: `${api_base_url}/courses`,
    method: 'GET'
  });

  setState({
    courses: courses_res.data
  });
};

export default fetch_data;
